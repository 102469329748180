import "./About.scss";
import avatar from "../img/avatar.jpg";
import hobby from "../img/hobby.png";
const About = () => {
  return (
    <section className="about" id="about">
      <div className="main-container">
        <div className="heading">
          <span className="heading-sec__main">About me</span>
          <span className="heading-sec__sub">
            Here you will find more information about me, and my current skills
            in terms of technology.
          </span>
        </div>
        <div className="about__content">
          <div className="about__content-main">
            <h3 className="about__content-title">Get to know me!</h3>
            <div className="about__content-details">
              <p className="about__content-details-para">
                With one year of experience in software development, I possess a
                strong skill set in Java (SpringBoot), PHP (WordPress,
                CodeIgniter) and web development frameworks, along with a
                passion for building secure and user-friendly software
                solutions.
              </p>
              <p className="about__content-details-para">
                In my previous role at ITC Group, I had the opportunity to
                contribute to various projects, including:
              </p>
              <ul>
                <li className="about__content-details-para">
                  <strong>The testcase management system:</strong> I leveraged
                  my skills in Java (Spring Boot) to create a user-friendly
                  website for managing test cases for QA. During this project, I
                  gained hands-on experience with microservices architecture and
                  the RabbitMQ message streaming system. Additionally, I
                  optimized the performance of the landing page, which was built
                  using PHP Wordpress.
                </li>
                <li className="about__content-details-para">
                  <strong>
                    The healthcare system for mental health support:
                  </strong>{" "}
                  I played a key role in this project by fixing security bugs,
                  creating UI components, and implementing notification
                  functionalities. Additionally, I leveraged my skills in PHP
                  (CodeIgniter) and Java (Spring Boot) to create a user-friendly
                  interface and integrate the system with an e-commerce platform
                  for demonstration purposes
                </li>
                <li className="about__content-details-para">
                  <strong>The marketing website for insurance products:</strong>{" "}
                  This project involved utilizing ACF Pro to create a
                  user-friendly content management system, enabling
                  non-technical users to maintain website content and customize
                  the website's style effortlessly.
                </li>
                <li className="about__content-details-para">
                  <strong>The landing page in the loyalty point system:</strong>{" "}
                  I leveraged my skills in PHP (WordPress) to develop a dynamic
                  landing page for the loyalty point system. This landing page
                  effectively showcased the system's functionality.
                  Additionally, I integrated the loyalty point system's API with
                  an e-commerce platform (OpenCart 3) for demonstration
                  purposes.
                </li>
              </ul>
              <p className="about__content-details-para">
                You'll find me an enthusiastic, friendly and reliable person.
                Additionally, I have time management, problem solvings and
                detail-oriented. Moreover, I can collaborate with other people
                to complete tasks. I am willing to learn new knowledge to meet
                job requirements
              </p>
            </div>
            <a href="/contact" className="btn" style={{width:"fit-content"}}>
              Contact
            </a>
          </div>
          <div className="about__content-skills">
            <h3 className="about__content-title">My technical Skills</h3>
            <h4 className="about__content-title">#Frontend:</h4>
            <div className="skills">
              <div className="skills__skill">HTML</div>
              <div className="skills__skill">CSS</div>
              <div className="skills__skill">Javascript</div>
              <div className="skills__skill">Jquery</div>
              <div className="skills__skill">ReactJs</div>
              <div className="skills__skill">Angular</div>
              <div className="skills__skill">Bootstrap</div>
            </div>
            <h4 className="about__content-title mt-5">#Backend:</h4>
            <div className="skills">
              <div className="skills__skill">PHP</div>
              <div className="skills__skill">Wordpress</div>
              <div className="skills__skill">CodeIgniter</div>
              <div className="skills__skill">Java</div>
              <div className="skills__skill">Spring boot</div>
              <div className="skills__skill">Spring security</div>
              <div className="skills__skill">JWT</div>
            </div>
            <h4 className="about__content-title mt-5">#Database Management:</h4>
            <div className="skills">
              <div className="skills__skill">MySQL</div>
              <div className="skills__skill">SQLServer</div>
              <div className="skills__skill">PostgreSQL</div>
              <div className="skills__skill">MongoDB</div>
            </div>
            <h4 className="about__content-title mt-5">
              #Software Development Life Cycle:
            </h4>
            <div className="skills">
              <div className="skills__skill">Agile & Scrum Methodologies</div>
              <div className="skills__skill">Waterfall</div>
            </div>
            <h4 className="about__content-title mt-5">
              #Source version control:
            </h4>
            <div className="skills">
              <div className="skills__skill">Git</div>
            </div>
            <h4 className="about__content-title mt-5">#Principles:</h4>
            <div className="skills">
              <div className="skills__skill">DSA</div>
              <div className="skills__skill">OOP</div>
              <div className="skills__skill">Networking</div>
            </div>
            <h4 className="about__content-title mt-5">#Others:</h4>
            <div className="skills">
              <div className="skills__skill">Postman</div>
              <div className="skills__skill">Docker</div>
              <div className="skills__skill">RabbitMQ</div>
              <div className="skills__skill">K6</div>
              <div className="skills__skill">AWS (EC2 - S3)</div>
              <div className="skills__skill">Swagger</div>
              <div className="skills__skill">Jira</div>
              <div className="skills__skill">Trello</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
