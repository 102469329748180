import "./Introduction.scss";
import linkedin from "../img/linkedin.png";
import github from "../img/github.png";
import facebook from "../img/facebook.png";
import gmail from "../img/gmail.png";
// import Nav from "./Nav";
// import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
// import Contact from "./Contact";

const Introduction = () => {
  return (
    <>
      <section id="home" className="home-hero">
        <div className="home-hero__content">
          <h1 className="heading-primary">Hello, I'm Gia Phat</h1>
          <div className="home-hero__info">
            <p className="text-primary-info">
              I'm a Software Engineer with more than one year of experience in
              software/web development. I specialize in Java Springboot. I
              contributed to the success of various practical projects with
              various business domains such as Healthcare, Insurance, Testcase
              management.
            </p>
          </div>
          <div className="home-hero__cta">
            <a href="/project" className="button button--bg">
              Projects
            </a>
          </div>
        </div>
        <div className="home-hero__socials">
          <div className="home-hero__social">
            <a
              href="https://www.linkedin.com/in/phattran2002/"
              className="home-hero__social-icon-link"
              target="_blank"
            >
              <img src={linkedin} alt="" className="home-hero__social-icon" />
            </a>
          </div>
          <div className="home-hero__social">
            <a
              href="https://github.com/Driss-Tran"
              className="home-hero__social-icon-link"
              target="_blank"
            >
              <img src={github} alt="" className="home-hero__social-icon" />
            </a>
          </div>
          <div className="home-hero__social">
            <a
              href="https://www.facebook.com/jphat2407/"
              className="home-hero__social-icon-link"
              target="_blank"
            >
              <img src={facebook} alt="" className="home-hero__social-icon" />
            </a>
          </div>
          <div className="home-hero__social">
            <a
              href="mailto:giaphat24072002@gmail.com"
              className="home-hero__social-icon-link"
              target="_blank"
            >
              <img src={gmail} alt="" className="home-hero__social-icon" />
            </a>
          </div>
        </div>
      </section>
    </>
  );
};

export default Introduction;
