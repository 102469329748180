import "./Introduction.scss";
import "./About.scss";
import "./Project.scss";

import project1 from "../img/project1.png";
import project2 from "../img/project2.png";
import project3 from "../img/project3.png";
// import { Form } from "react-bootstrap";
const Project = () => {
  return (
    <section className="project">
      <div className="main-container">
        <div className="heading">
          <span className="heading-sec__main">Projects</span>
          <span className="heading-sec__sub">
            Here you will find some of the personal and clients projects that I
            created with each project containing its own case study.
          </span>
        </div>
        <div className="projects__content">
          <div className="projects__row">
            <div className="projects__row-img-cont">
              <img className="img-thumbnail" src={project1} alt="Project 1" loading="lazy" />
            </div>
            <div className="projects__row-content">
              <h3 className="projects__row-content-title">
                Tour Booking Landing Page
              </h3>
              <p className="projects__row-content-desc">
                This was my first project. I just cloned the UI of a tour
                booking website to practice my skills.
              </p>
              <p className="projects__row-content-desc tech_stacks">
                <strong style={{ color: "#333" }}>Tech stacks : </strong> HTML,
                CSS, Javascript and Bootstrap
              </p>
              <a href="https://driss-tran.github.io/Traveling_Landing_Page/" class="projects__row_button text-decoration-none">
                Link Project
              </a>
            </div>
          </div>
          <div className="projects__row">
            <div className="projects__row-img-cont">
              <img className="img-thumbnail" src={project2} alt="Project 1" loading="lazy" />
            </div>
            <div className="projects__row-content">
              <h3 className="projects__row-content-title">
                PPS Banking website
              </h3>
              <p className="projects__row-content-desc">
                This was the website about banking industries and I collaborated
                with my teammates to complete it. This project has different
                functions such as{" "}
                <i>
                  Login, Transaction, Check Transaction's History, Admin,
                  Deposit, Transaction's confirmation, OTP.{" "}
                </i>
              </p>
              <p className="projects__row-content-desc tech_stacks">
                <strong style={{ color: "#333" }}>Tech stacks : </strong> HTML,
                CSS, Javascript, Bootstrap, MySQL and PHP.
              </p>
              <a href="https://github.com/Driss-Tran/Ecommerce_Services_Booking" class="projects__row_button text-decoration-none">
                Link Project
              </a>
            </div>
          </div>
          <div className="projects__row">
            <div className="projects__row-img-cont">
              <img className="img-thumbnail" src={project3} alt="Project 1" loading="lazy" />
            </div>
            <div className="projects__row-content">
              <h3 className="projects__row-content-title">
                Travel Booking Website
              </h3>
              <p className="projects__row-content-desc">
                This was the website I did after learning{" "}
                <i className="fw-bold">C# Technology</i> at the University.
                Additionally, This projects has different functions such as{" "}
                <i>Login, Show Travel or Hotel Booking, Show Categories, Cart, Payment, Admin.</i>
              </p>
              <p className="projects__row-content-desc tech_stacks">
                <strong style={{ color: "#333" }}>Tech stacks : </strong> HTML,
                CSS, Javascript, Bootstrap, SQLServer and C# - Asp .NET (Version 5).
              </p>
              <a href="https://phat247.000webhostapp.com/" class="projects__row_button text-decoration-none">
                Link Project
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Project;
