import "./App.scss";
import Introduction from "./view/Introduction";
import Contact from "./view/Contact";
import About from "./view/About";
import Project from "./view/Project";
import "./view/Introduction.scss";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Nav from "./view/Nav";
function App() {
  return (
    <div className="container-fluid px-0">
      <Router>
        <div className="nav-div">
          <Nav></Nav>
        </div>
        <Switch>
          <Route index path="/" exact component={Introduction}></Route>
          <Route path="/contact" component={Contact}></Route>
          <Route path="/about" component={About}></Route>
          <Route path="/project" component={Project}></Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
